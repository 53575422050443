import "./App.scss";
import * as actions from "./state/actions";
import Preview from "./components/preview";
import StateContext from "./state/context";
import reducer, { defaultState } from "./state/reducers";
import { useEffect, useReducer, useState } from "react";
import { postMessage } from "./utils";

// addEventListener support for IE8
function bindEvent(element: any, eventName: any, eventHandler: any) {
  if (element.addEventListener) {
    element.addEventListener(eventName, eventHandler, false);
  } else if (element.attachEvent) {
    element.attachEvent('on' + eventName, eventHandler);
  }
}
const App = () => {
  const [state, dispatch] = useReducer(reducer, defaultState);
  const [style, setStyle] = useState({});
  useEffect(() => {
    // Listen to messages from parent window
    bindEvent(window, 'message', function (e: any) {
      if (e.data && e.data.source === 'FLEX-SIMULATOR') {
        try {
          setStyle(e.data.style || {
            backgroundColor: '#849ebf'
          });
          const eventData = e.data.data;
          if (state.config !== eventData.config) {
            dispatch(actions.config(eventData.config));
            const onChangeHeight = setInterval(() => {
              try {
                const cards = Array.from(window.document.getElementsByClassName('LySlider'));
                const clientHeight = cards.map(card => card.clientHeight);
                const heightNow = Math.max(...clientHeight);
                postMessage({
                  action: 'SET_HEIGHT',
                  payload: heightNow,
                  id: eventData.id
                });
                dispatch(actions.maxCardHeight(heightNow));
                clearInterval(onChangeHeight);
              } catch (err) {
                console.error('Can not pass a value to a parent iframe', err);
              }
            }, 100);
          }
          if (state.flexErrorPath !== eventData.flexErrorPath) {
            dispatch(actions.flexErrorPath(eventData.flexErrorPath));
          }
          if (state.componentPath !== eventData.componentPath) {
            dispatch(actions.onMouseEnterComponent(eventData.componentPath));
          }
          if (state.isEditerMode !== eventData.isEditerMode) {
            dispatch(actions.isEditerMode(eventData.isEditerMode));
          }
        } catch (err) {
          console.error('Get message from parent window error', err);
        }
      }
    });
  }, [dispatch, setStyle, state.componentPath, state.config, state.flexErrorPath, state.isEditerMode]);
  return <StateContext.Provider value={{
    state,
    dispatch
  }}>
			<div id='root-container' className='container'>
				<div className='preview-container' style={style}>
					<Preview />
				</div>
			</div>
		</StateContext.Provider>;
};
export default App;