import * as types from './types'

export const onComponentSelecetd = (config: object) => ({
	type: types.ON_COMPONENT_SELECTED,
	payload: config,
})

export const onComponentChanged = (config: object) => ({
	type: types.ON_COMPONENT_CHANGED,
	payload: config,
})

export const config = (config: any) => ({
	type: types.CONFIG,
	payload: config,
})

export const isEditingJson = (status: boolean) => ({
	type: types.IS_EDITING_JSON,
	payload: status,
})

export const onBackConfigClicked = () => ({
	type: types.ON_BACK_CONFIG_CLICKED,
})

export const onNextConfigClicked = () => ({
	type: types.ON_NEXT_CONFIG_CLICKED,
})

export const selectedBackup = (index: number) => ({
	type: types.SELECTED_BACKUP,
	payload: index,
})

export const flexErrorPath = (error: any) => ({
	type: types.FLEX_ERROR,
	payload: error,
})

export const maxCardHeight = (height: any) => ({
	type: types.MAX_CARD_HEIGHT,
	payload: height,
})

export const onMouseEnterComponent = (componentPath: any) => ({
	type: types.ON_MOUSE_ENTER_COMPONENT,
	payload: componentPath,
})

export const isEditerMode = (mode: any) => ({
	type: types.IS_EDITER_MODE,
	payload: mode
})
