import * as types from "./types";

export interface State {
	selectedComponent?: any;
	config: {
		type: string;
		contents: [];
	};
	isEditingJson: boolean;
	backupConfigs: any[];
	selectedBackup: number | null;
	flexErrorPath: [];
	maxCardHeight: number;
	componentPath: "";
	isEditerMode: false;
}

export const defaultState: State = {
	selectedComponent: {},
	config: {
		type: "carousel",
		contents: [],
	},
	isEditingJson: false,
	backupConfigs: [],
	selectedBackup: null,
	flexErrorPath: [],
	maxCardHeight: 0,
	componentPath: "",
	isEditerMode: false,
};

export default function reducer(state: State, action: any) {
	switch (action.type) {
		case types.ON_COMPONENT_SELECTED:
			return {
				...state,
				selectedComponent: action.payload,
			};
		case types.ON_COMPONENT_CHANGED:
			return {
				...state,
				selectedComponent: action.payload,
			};
		case types.SELECTED_BACKUP:
			return {
				...state,
				selectedBackup: action.payload,
			};
		case types.CONFIG: {
			const backupConfigs = state.backupConfigs;
			const newBackupConfigs = [...backupConfigs, state.config];

			if (newBackupConfigs.length >= 5) {
				newBackupConfigs.shift();
			}

			return {
				...state,
				backupConfigs: newBackupConfigs,
				config: action.payload,
				selectedBackup: null,
			};
		}
		case types.IS_EDITING_JSON:
			return {
				...state,
				isEditingJson: action.payload,
			};
		case types.FLEX_ERROR:
			return {
				...state,
				flexErrorPath: action.payload,
			};
		case types.MAX_CARD_HEIGHT:
			return {
				...state,
				maxCardHeight: action.payload,
			};
		case types.ON_MOUSE_ENTER_COMPONENT:
			return {
				...state,
				componentPath: action.payload,
			};
		case types.IS_EDITER_MODE:
			return {
				...state,
				isEditerMode: action.payload,
			};
		default:
			return {
				...state,
			};
	}
}
