export function postMessage(payload: any) {
	if(window.ReactNativeWebView) {
		// React Native
		window.ReactNativeWebView.postMessage(payload)
	} else if (window.opener) {
		// Web new window
		window.opener.postMessage(payload, '*')
		window.close()
	} else if (window.parent) {
		// Web iframe
		window.parent.postMessage(payload, '*')
	}
}	