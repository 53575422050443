export const ON_COMPONENT_SELECTED = 'ON_COMPONENT_SELECTED'
export const ON_COMPONENT_CHANGED = 'ON_COMPONENT_CHANGED'
export const CONFIG = 'CONFIG'
export const IS_EDITING_JSON = 'IS_EDITING_JSON'
export const ON_BACK_CONFIG_CLICKED = 'ON_BACK_CONFIG_CLICKED'
export const ON_NEXT_CONFIG_CLICKED = 'ON_NEXT_CONFIG_CLICKED'
export const SELECTED_BACKUP = 'SELECTED_BACKUP'
export const FLEX_ERROR = 'FLEX_ERROR'
export const MAX_CARD_HEIGHT = 'MAX_CARD_HEIGHT'
export const ON_MOUSE_ENTER_COMPONENT = 'ON_MOUSE_ENTER_COMPONENT'
export const IS_EDITER_MODE = 'IS_EDITER_MODE'
