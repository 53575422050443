import "./style.scss";
import StateContext from "../../state/context";
import WarningIcon from "@mui/icons-material/Warning";
import { useCallback, useContext, useEffect } from "react";
import { postMessage } from "../../utils";

//Name Class Flex
const flexNameMain = "flexRender";
const flexNameMainItem = "lyInner";
const flexNameItem = "lyItem";
const flexNameItemSub = "T1";
const flexNameHeader = "t1Header";
const flexNameHero = "t1Hero";
const flexNameBody = "t1Body";
const flexNameFooter = "t1Footer";
const flexNameBox = "MdBx";
const flexNameText = "MdTxt";
const flexNameIcon = "MdIco";
const flexNameButton = "MdBtn";
const flexNameImage = "MdImg";
const flexNameSpan = "MdSpn";
const flexNameFiller = "mdBxFiller";
const flexNameSpacer = "mdBxSpacer";
const flexNameSeparator = "MdSep";

//Constant
const pathSectionNameHeader = `header`;
const pathSectionNameHero = `hero`;
const pathSectionNameBody = `body`;
const pathSectionNameFooter = `footer`;
const backgroundColorHighlight = "#dcdcdc";
const opacityHighlight = "0.5";
const flexIdMainItem = "MainItem";
function getNamePatternContent(index: number) {
  return `contents[${index}]`;
}
function getClassName(element: HTMLElement | Element) {
  return element.className.split(" ").find(name => {
    switch (name) {
      case flexNameMain:
        return true;
      case flexNameMainItem:
        return true;
      case flexNameItem:
        return true;
      case flexNameItemSub:
        return true;
      case flexNameHeader:
        return true;
      case flexNameHero:
        return true;
      case flexNameBody:
        return true;
      case flexNameFooter:
        return true;
      case flexNameBox:
        return true;
      case flexNameText:
        return true;
      case flexNameIcon:
        return true;
      case flexNameButton:
        return true;
      case flexNameImage:
        return true;
      case flexNameSpan:
        return true;
      case flexNameFiller:
        return true;
      case flexNameSpacer:
        return true;
      case flexNameSeparator:
        return true;
      default:
        return false;
    }
  });
}
const FlexSimulator = () => {
  const {
    state
  } = useContext(StateContext);
  const {
    config,
    flexErrorPath,
    componentPath,
    isEditerMode
  } = state;
  const getElementFlexRender = () => document.getElementById(flexNameMain);
  const registerEventFlex = useCallback((flexChildElement: Element, path: string) => {
    flexChildElement.addEventListener("click", (e: Event) => {
      const flexElement = document.getElementById(path);
      if (flexElement) {
        e.preventDefault();
        postMessage({
          action: "ON_MOUSE_ENTER_IMAGE",
          path: path
        });
        e.stopPropagation();
      }
    });
    flexChildElement.addEventListener("mouseover", (e: Event) => {
      e.stopPropagation();
      const flexElement = document.getElementById(path);
      if (flexElement) {
        const className = getClassName(flexElement);
        switch (className) {
          case flexNameImage:
            flexElement.style.opacity = opacityHighlight;
            break;
          default:
            flexElement.style.backgroundColor = backgroundColorHighlight;
            break;
        }
      }
    });
    flexChildElement.addEventListener("mouseout", (e: Event) => {
      e.stopPropagation();
      const flexElement = document.getElementById(path);
      if (flexElement) {
        const className = getClassName(flexElement);
        switch (className) {
          case flexNameImage:
            flexElement.style.opacity = flexElement.getAttribute("opacity") || "";
            break;
          default:
            flexElement.style.backgroundColor = flexElement.getAttribute("backgroundColor") || "";
            break;
        }
      }
    });
    const flexElement = document.getElementById(path);
    if (flexElement) {
      flexChildElement.setAttribute("backgroundColor", flexElement.style.backgroundColor);
      flexChildElement.setAttribute("opacity", flexElement.style.opacity);
    }
  }, []);
  const registerIdOnClass = useCallback((element: Element | null, path: string, isTagNotClassFlex: boolean) => {
    const formatPath = (pathNow: string, name: string) => {
      return `${pathNow}.${name}`;
    };
    const flexSection = (pathNow: string, flexChildElement: Element) => {
      if (flexChildElement.parentElement) {
        const classNameParent = getClassName(flexChildElement.parentElement) || "";
        switch (classNameParent) {
          case flexNameHeader:
            flexChildElement.id = formatPath(pathNow, pathSectionNameHeader);
            return flexChildElement.id;
          case flexNameHero:
            flexChildElement.id = formatPath(pathNow, pathSectionNameHero);
            return flexChildElement.id;
          case flexNameBody:
            flexChildElement.id = formatPath(pathNow, pathSectionNameBody);
            return flexChildElement.id;
          case flexNameFooter:
            flexChildElement.id = formatPath(pathNow, pathSectionNameFooter);
            return flexChildElement.id;
          default:
            return "";
        }
      } else {
        return "";
      }
    };
    if (element) {
      const flexChildren = element.children;
      const flexChildrenLength = flexChildren.length;
      for (let index = 0; index < flexChildrenLength; index++) {
        const flexChildElement = flexChildren[index];
        const className = getClassName(flexChildElement);
        let pathNow = path;
        let flexSectionValue: string;
        switch (className) {
          case flexNameHeader:
            flexChildElement.id = getNamePatternContent(Number(flexChildElement.parentElement?.id));
            pathNow = flexChildElement.id;
            break;
          case flexNameHero:
            flexChildElement.id = getNamePatternContent(Number(flexChildElement.parentElement?.id));
            pathNow = flexChildElement.id;
            break;
          case flexNameBody:
            flexChildElement.id = getNamePatternContent(Number(flexChildElement.parentElement?.id));
            pathNow = flexChildElement.id;
            break;
          case flexNameFooter:
            flexChildElement.id = getNamePatternContent(Number(flexChildElement.parentElement?.id));
            pathNow = flexChildElement.id;
            break;
          case flexNameBox:
            flexSectionValue = flexSection(pathNow, flexChildElement);
            if (!flexSectionValue) {
              flexChildElement.id = formatPath(pathNow, getNamePatternContent(index));
              pathNow = flexChildElement.id;
            } else {
              pathNow = flexSectionValue;
            }
            if (flexChildElement.children.length > 0) {
              isTagNotClassFlex = true;
            }
            break;
          case flexNameText:
            flexSectionValue = flexSection(pathNow, flexChildElement);
            if (!flexSectionValue) {
              flexChildElement.id = formatPath(pathNow, getNamePatternContent(index));
              pathNow = flexChildElement.id;
            } else {
              pathNow = flexSectionValue;
            }
            if (flexChildElement.children.length > 0) {
              isTagNotClassFlex = true;
            }
            break;
          case flexNameIcon:
            flexSectionValue = flexSection(pathNow, flexChildElement);
            if (!flexSectionValue) {
              flexChildElement.id = formatPath(pathNow, getNamePatternContent(index));
              pathNow = flexChildElement.id;
            } else {
              pathNow = flexSectionValue;
            }
            if (flexChildElement.children.length > 0) {
              isTagNotClassFlex = true;
            }
            break;
          case flexNameButton:
            flexSectionValue = flexSection(pathNow, flexChildElement);
            if (!flexSectionValue) {
              flexChildElement.id = formatPath(pathNow, getNamePatternContent(index));
              pathNow = flexChildElement.id;
            } else {
              pathNow = flexSectionValue;
            }
            if (flexChildElement.children.length > 0) {
              isTagNotClassFlex = true;
            }
            break;
          case flexNameImage:
            flexSectionValue = flexSection(pathNow, flexChildElement);
            if (!flexSectionValue) {
              flexChildElement.id = formatPath(pathNow, getNamePatternContent(index));
              pathNow = flexChildElement.id;
            } else {
              pathNow = flexSectionValue;
            }
            if (flexChildElement.children.length > 0) {
              isTagNotClassFlex = true;
            }
            break;
          case flexNameSpan:
            flexSectionValue = flexSection(pathNow, flexChildElement);
            if (!flexSectionValue) {
              flexChildElement.id = formatPath(pathNow, getNamePatternContent(index));
              pathNow = flexChildElement.id;
            } else {
              pathNow = flexSectionValue;
            }
            if (flexChildElement.children.length > 0) {
              isTagNotClassFlex = true;
            }
            break;
          case flexNameFiller:
            flexSectionValue = flexSection(pathNow, flexChildElement);
            if (!flexSectionValue) {
              flexChildElement.id = formatPath(pathNow, getNamePatternContent(index));
              pathNow = flexChildElement.id;
            } else {
              pathNow = flexSectionValue;
            }
            if (flexChildElement.children.length > 0) {
              isTagNotClassFlex = true;
            }
            break;
          case flexNameSpacer:
            flexSectionValue = flexSection(pathNow, flexChildElement);
            if (!flexSectionValue) {
              flexChildElement.id = formatPath(pathNow, getNamePatternContent(index));
              pathNow = flexChildElement.id;
            } else {
              pathNow = flexSectionValue;
            }
            if (flexChildElement.children.length > 0) {
              isTagNotClassFlex = true;
            }
            break;
          case flexNameSeparator:
            flexSectionValue = flexSection(pathNow, flexChildElement);
            if (!flexSectionValue) {
              flexChildElement.id = formatPath(pathNow, getNamePatternContent(index));
              pathNow = flexChildElement.id;
            } else {
              pathNow = flexSectionValue;
            }
            if (flexChildElement.children.length > 0) {
              isTagNotClassFlex = true;
            }
            break;
          default:
            if (isTagNotClassFlex) {
              flexChildElement.id = pathNow;
              isTagNotClassFlex = false;
              if (flexChildElement.children.length > 0) {
                isTagNotClassFlex = true;
              }
            }
            break;
        }
        if (className !== flexNameItem && className !== flexNameItemSub) {
          registerEventFlex(flexChildElement, pathNow);
        }
        registerIdOnClass(flexChildElement, pathNow, isTagNotClassFlex);
      }
      return;
    }
    return;
  }, [registerEventFlex]);
  useEffect(() => {
    const removeElementOldBeforeRender = () => {
      const flexRender = getElementFlexRender();
      while (flexRender?.firstChild) {
        flexRender.removeChild(flexRender?.firstChild);
      }
    };
    if (config.contents.length > 0 && (flexErrorPath === undefined || flexErrorPath.length === 0)) {
      const data: {
        type: string;
        contents: any;
      } = {
        type: 'flex',
        contents: config
      };
      removeElementOldBeforeRender();
      (window as any).flex2html(flexNameMain, data);
      if (isEditerMode) {
        try {
          const flexElements = document.getElementsByClassName(flexNameMainItem);
          if (flexElements.length > 0) {
            const flexChildren = flexElements[0].children;
            for (let index = 0; index < flexChildren.length; index++) {
              const flexChildElement = flexChildren[index];
              flexChildElement.id = `${index}`;
              flexChildElement.children[0].id = `${index}`;
              registerIdOnClass(flexChildElement, "", false);
            }
          }
          flexElements[0].id = flexIdMainItem;
          const flexMainItem = document.getElementById(flexIdMainItem);
          if (flexMainItem) {
            flexMainItem.style.flexWrap = "wrap";
            flexMainItem.style.justifyContent = "center";
            flexMainItem.style.alignItems = "baseline";
          }
        } catch (error) {}
      }
    }
  }, [config, flexErrorPath, isEditerMode, registerIdOnClass]);
  useEffect(() => {
    if (componentPath && isEditerMode) {
      try {
        const flexElement = document.getElementById(componentPath);
        if (flexElement) {
          const className = getClassName(flexElement);
          switch (className) {
            case flexNameImage:
              flexElement.style.opacity = opacityHighlight;
              break;
            default:
              flexElement.style.backgroundColor = backgroundColorHighlight;
              break;
          }
        }
      } catch (error) {}
    }
  }, [componentPath, isEditerMode]);
  return <>
			<div id={flexNameMain} className='simulator-container' />
			{flexErrorPath !== undefined && flexErrorPath.length !== 0 && <div className="simulator-error">
					<div className="error-container">
						{flexErrorPath.map((error, index) => <div className="error-message" key={index}>
								<WarningIcon style={{
            marginRight: '10px'
          }} /> {error} invalid property
							</div>)}
					</div>
				</div>}
		</>;
};
export default FlexSimulator;