import { State } from './reducers'
import { createContext } from 'react'

interface StateContext {
	state: State
	dispatch: any
}

const defaultStateContext: StateContext = {
	state: {
		config: {
			type: 'carousel',
			contents: [],
		},
		isEditingJson: false,
		backupConfigs: [],
		selectedBackup: null,
		flexErrorPath: [],
		maxCardHeight: 0,
		componentPath: "",
		isEditerMode: false,
	},
	dispatch: null,
}

const StateContext = createContext(defaultStateContext)

export default StateContext
